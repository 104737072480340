<template>
    
</template>
<script>
import {Howl, Howler} from 'howler';
export default {
  name: 'Howler',
  props: {
    artist: String,
    title: String,
    url: String,
    artworkUrl: String,
    volume: Number
  },
  data(){
    return {
      playing: false,
      loading: false
    }
  },
  watch: { 
    volume(vol){ 
      Howler.volume(parseInt(vol)/100)
      localStorage.volume = vol
    },
    playing(playing){
      this.$parent.playing = playing
      if(playing){
        navigator.mediaSession.playbackState = 'playing';
      }else{
        navigator.mediaSession.playbackState = 'paused';
      }
    },
    loading(loading){
      this.$parent.loading = loading
    },
    artist(){this.updateMetadata()},
    title(){this.updateMetadata()},
    artworkUrl(){this.updateMetadata()},
  },
  methods:{
    updateMetadata(){
      const aw = this.artworkUrl || this.$parent.$refs.artwork.artworkBgUrl
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.title,
        artist: this.artist,
        artwork: [{"src": aw}]
      });
    },
    onplay(){
      this.loading = false
      this.playing = true
    },
    onstop(){
      this.loading = false
      this.playing = false
    },
    toggle(){
      if(this.loading) return
      if(this.playing){
        this.stop()
      }else{
        this.play()
      }
    },
    stop(){
      if(this.howler){
        this.howler.stop()
        this.playing = false
        this.loading = false
      }
    },
    play(){
      this.loading = true
      if(!this.howler) this.initHowler()
      Howler.volume(this.volume/100)
      this.howler.play()
    },
    initHowler(){
      this.howler = new Howl({
        src: [this.url],
        html5: true,
        autoplay: true,
        onstop: ()=>this.onstop(),
        onplay: ()=>this.onplay(),
        onplayerror: ()=>{
          this.playing = false
          this.howler.once("unlock",()=>{
            if(this.loading){
              this.play()
            }
          })
        }
      })
    },
    setVolume(event){
      this.volume = event.target.value
    }
  },
  mounted: function () {
  }
}

</script>