// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

/* eslint no-console: 0 */
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"
import Vue from 'vue'
import VueRouter from 'vue-router'
import Player from './Player.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

Vue.use(VueRouter)

library.add(fas)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const elementId = "player"
  const element = document.getElementById(elementId)
  const props = JSON.parse(element.getAttribute('data'))
  
  const app = new Vue({
    render: h => h(Player,{ props }),
  }).$mount("#"+elementId)

})
