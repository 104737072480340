<template>
    <section ref="artworkContainer" id="artwork">
        <img :src="artworkUrl" ref="artworkImg">
    </section>
</template>

<script>
import GeoPattern from 'geopattern'
// import axios from 'axios'

export default {
  name: 'Artwork',
  props:{
    artworkUrl: String
  },
  data() {
    return { 
      artworkBgUrl: ""
    }
  },
  watch: { 
    artworkBgUrl: function(newVal) { // watch it
        document.body.style.backgroundImage = "url('"+newVal+"')"
    },
    artworkUrl: function(url){
      this.setUrl(url)
    }
  },
  methods: {
    generateArtworkImg(pattern,ret){
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var img = new Image();
      img.src = pattern.toDataUri();
      img.crossOrigin = "anonymous";

      img.onload = function() {
        canvas.width = 1024;
        canvas.height = 1024;
        img.crossOrigin = "anonymous";
        var ptrn = ctx.createPattern(img, 'repeat')
        ctx.fillStyle = ptrn;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        var dataurl = canvas.toDataURL("image/png");
        ret(dataurl);
        
      };

      img.crossOrigin = "";
    },
    generateArtwork(){
      var pattern = GeoPattern.generate(this.$parent.artist+' '+this.$parent.title)
      return pattern.toDataUri();
    },
    setUrl(artworkUrl){
      var dodo = true;
      if(artworkUrl && dodo){
        this.artworkBgUrl = artworkUrl
        document.body.style.backgroundRepeat = "no-repeat"
        document.body.style.backgroundSize = "cover"
        document.body.style.backdropFilter = "blur(54px) saturate(2.2)"
        this.$refs.artworkImg.style.display = null
        this.$refs.artworkContainer.style.backgroundImage = null
      }else{
        var pattern = GeoPattern.generate(this.$parent.artist+' '+this.$parent.title+Math.random())
        this.artworkBgUrl = pattern.toDataUri()
        this.$refs.artworkContainer.style.backgroundImage = pattern.toDataUrl()
        document.body.style.backgroundRepeat = "repeat"
        document.body.style.backgroundSize = "auto"
        document.body.style.backdropFilter = "blur(10px) saturate(2.2)"
        this.$refs.artworkImg.style.display = "none"
      }
    }
  }
}
</script>