<template>
    <section id="controls">
        <div class="info" v-cloak>
            <h2>{{ title }}</h2>
            <h3>{{ artist }}</h3>
        </div>
        
        <div class="row">
            <button v-on:click="$parent.vote(1)">
            <font-awesome-layers full-width class="fa-2x">
              <font-awesome-icon icon="smile"  transform="shrink-8"/>
              <font-awesome-layers-text :value="upvotes" position="top-left" />
            </font-awesome-layers>
            </button>
            <button id="play" v-on:click="toggle"><font-awesome-icon :icon="icon" :spin="iconSpin" /></button>
            <button v-on:click="$parent.vote(-1)">
            <font-awesome-layers full-width class="fa-2x">
              <font-awesome-icon icon="frown" transform="shrink-8"/>
              <font-awesome-layers-text :value="downvotes" position="bottom-right" transform="" />
            </font-awesome-layers>
            </button>
        </div>

      <div class="row volume">
        <input type="range" min="1" max="100" :value="volume" id="volumeSlider" v-on:input="setVolume">
      </div>

    </section>
</template>

<script>
import {Howl, Howler} from 'howler';
export default {
  name: 'Controls',
  props: {
    artist: String,
    title: String,
    upvotes: Number,
    downvotes: Number,
    playing: Boolean,
    loading: Boolean,
    volume: Number
  },
  data(){
    return {
      icon: "play",
      iconSpin: false,
    }
  },
  watch: { 
    volume(vol){ 
      this.$parent.volume = vol
    },
    loading(loading){
      if(loading){
        this.setLoading()
      }
    },
    playing(playing){
      this.setPlaying(playing)
    }
  },
  methods:{
    setPlaying(playing){
      if(playing){
        this.icon = "pause"
        this.iconSpin = false
      }else{
        this.icon = "play"
        this.iconSpin = false
      }
    },
    setLoading(){
      this.icon = "compact-disc"
      this.iconSpin = true
    },

    toggle(){
      this.$parent.$refs.howler.toggle()
    },
    setVolume(event){
      this.volume = parseInt(event.target.value)
    },
    keyPress(event){
      switch(event.code){
        case "Space": this.toggle()
      }
    }
  },
  mounted(){
    window.addEventListener("keypress", e => {
      this.keyPress(e)
    });
    // setTimeout(()=>{this.play()},1000)
  }
}
</script>