<template>
  <div id="player">
    
    <header>
			<img :src="require('../assets/images/logo3.svg').default" :class="{ 'flip' : params.is_dev}">
			<h1 :data-text="params.radio_name">{{params.radio_name}}.</h1>&nbsp;
      <p v-if="params.is_dev">dev {{params.version}}</p>
		</header>

    <Artwork ref="artwork" v-bind:artworkUrl="artworkUrl"/>
    <Controls ref="controls" v-bind:artist="artist" v-bind:title="title" v-bind:upvotes="upvotes" v-bind:downvotes="downvotes" v-bind:playing="playing" v-bind:loading="loading" v-bind:volume="volume"/>
    <Howler ref="howler" v-bind:artworkUrl="artworkUrl" v-bind:artist="artist" v-bind:title="title" v-bind:url="params.url" v-bind:volume="volume" />
		<footer>	
		</footer>
  </div>
  
</template>

<script>
import Artwork from './components/Artwork.vue'
import Controls from './components/Controls.vue'
import Howler from './components/Howler.vue'
import sha1 from 'js-sha1'
import consumer from "./channels/consumer"

// import axios from 'axios'

 
export default {
  name: 'Player',
  props: ['params'],
  components: {
    Artwork,
    Controls,
    Howler
  },
  data(){
    return {
      artist: "",
      title: "",
      artworkUrl: undefined,
      upvotes: 0,
      downvotes: 0,
      userid: null,
      playing: false,
      loading: false,
      volume: 100
    }
  },
  watch: {
    title(){
      var current_title = this.artist+" - "+this.title
      var play_id = sha1(current_title+Date.now())
      if (Storage.prototype.hasOwnProperty.call(localStorage,"title")) {
          var store_title = localStorage.getItem("title")
          if(store_title != current_title){
              localStorage.setItem("play_id",play_id)
              localStorage.setItem("title",current_title)
          }
      }
      else {
          localStorage.setItem("play_id",play_id)
          store_title = localStorage.setItem("title",current_title) 
      }
      document.title = `${this.title} - ${this.artist} - ${this.$props.params.radio_name}.`
    },
    volume(volume){
      localStorage.volume = volume
    },
    artworkUrl(url){
      this.$refs.artwork.setUrl(url)
    }
  },
  methods: {
    processNow(nowData){
      if(this.artist != nowData.artist || this.title != nowData.title){
        this.artist = nowData.artist
        this.title = nowData.title
      }
      this.upvotes = nowData.upvotes
      this.downvotes = nowData.downvotes
    },
    onMessage(event){
      var data = event
      if(data.now){
        this.processNow(data.now)
      }
      if(data.artwork !== undefined && data.artwork.url !== undefined){
        this.artworkUrl = data.artwork.url;
      }
    },
    connectWS(){
      this.ws = consumer.subscriptions.create({ channel: "PlayerChannel"},{ received: this.onMessage } )
    },
    vote(vote){
      var user_id = localStorage.getItem("user_id")
      var play_id = localStorage.getItem("play_id")
      var data = {"user_id":user_id,"play_id":play_id,"vote":vote}
      this.ws.send({"vote":data})
    },
    newID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c)=>{
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
    }
  },
  mounted: function () {
    if (localStorage.volume) {
      this.volume = parseInt(localStorage.volume);
    }
    this.connectWS()
    if (Storage.prototype.hasOwnProperty.call(localStorage,"user_id")) {
        this.user_id = localStorage.getItem("user_id")
    }
    else {
        this.user_id = localStorage.setItem("user_id",this.newID()) 
    }
  }
}
</script>
 
<style lang="scss">
  @import '../assets/stylesheets/ob.scss' 
</style>