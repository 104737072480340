var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "player" } },
    [
      _c("header", [
        _c("img", {
          class: { flip: _vm.params.is_dev },
          attrs: { src: require("../assets/images/logo3.svg").default },
        }),
        _vm._v(" "),
        _c("h1", { attrs: { "data-text": _vm.params.radio_name } }, [
          _vm._v(_vm._s(_vm.params.radio_name) + "."),
        ]),
        _vm._v(" \n      "),
        _vm.params.is_dev
          ? _c("p", [_vm._v("dev " + _vm._s(_vm.params.version))])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("Artwork", { ref: "artwork", attrs: { artworkUrl: _vm.artworkUrl } }),
      _vm._v(" "),
      _c("Controls", {
        ref: "controls",
        attrs: {
          artist: _vm.artist,
          title: _vm.title,
          upvotes: _vm.upvotes,
          downvotes: _vm.downvotes,
          playing: _vm.playing,
          loading: _vm.loading,
          volume: _vm.volume,
        },
      }),
      _vm._v(" "),
      _c("Howler", {
        ref: "howler",
        attrs: {
          artworkUrl: _vm.artworkUrl,
          artist: _vm.artist,
          title: _vm.title,
          url: _vm.params.url,
          volume: _vm.volume,
        },
      }),
      _vm._v(" "),
      _c("footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }