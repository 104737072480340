var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { attrs: { id: "controls" } }, [
    _c("div", { staticClass: "info" }, [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("h3", [_vm._v(_vm._s(_vm.artist))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.$parent.vote(1)
            },
          },
        },
        [
          _c(
            "font-awesome-layers",
            { staticClass: "fa-2x", attrs: { "full-width": "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "smile", transform: "shrink-8" },
              }),
              _vm._v(" "),
              _c("font-awesome-layers-text", {
                attrs: { value: _vm.upvotes, position: "top-left" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        { attrs: { id: "play" }, on: { click: _vm.toggle } },
        [
          _c("font-awesome-icon", {
            attrs: { icon: _vm.icon, spin: _vm.iconSpin },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.$parent.vote(-1)
            },
          },
        },
        [
          _c(
            "font-awesome-layers",
            { staticClass: "fa-2x", attrs: { "full-width": "" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "frown", transform: "shrink-8" },
              }),
              _vm._v(" "),
              _c("font-awesome-layers-text", {
                attrs: {
                  value: _vm.downvotes,
                  position: "bottom-right",
                  transform: "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row volume" }, [
      _c("input", {
        attrs: { type: "range", min: "1", max: "100", id: "volumeSlider" },
        domProps: { value: _vm.volume },
        on: { input: _vm.setVolume },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }