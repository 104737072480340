var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { ref: "artworkContainer", attrs: { id: "artwork" } }, [
    _c("img", { ref: "artworkImg", attrs: { src: _vm.artworkUrl } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }